/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~ngx-toastr/toastr';

@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/modern/modern-light-theme';
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-radio-label {
  white-space: normal!important;
}

/* select-search-input */
.select-search-input{
  margin: 5px;
  padding: 5px;
  background: lightgray;
  color: #222;
  border-radius: 4px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label {
  background: #0878e2 !important;
  color: #FFFFFF;
}

body {
  overflow-x: hidden;
}
body {
  /*position: relative;*/
}
